import { getCurrentHub, trace } from '@sentry/core';
import type { Integration } from '@sentry/types';
import { addNonEnumerableProperty, logger } from '@sentry/utils';

import { shouldDisableAutoInstrumentation } from './utils/node-utils';

type PrismaAction =
  | 'findUnique'
  | 'findMany'
  | 'findFirst'
  | 'create'
  | 'createMany'
  | 'update'
  | 'updateMany'
  | 'upsert'
  | 'delete'
  | 'deleteMany'
  | 'executeRaw'
  | 'queryRaw'
  | 'aggregate'
  | 'count'
  | 'runCommandRaw';

interface PrismaMiddlewareParams {
  model?: unknown;
  action: PrismaAction;
  args: unknown;
  dataPath: string[];
  runInTransaction: boolean;
}

type PrismaMiddleware<T = unknown> = (
  params: PrismaMiddlewareParams,
  next: (params: PrismaMiddlewareParams) => Promise<T>,
) => Promise<T>;

interface PrismaClient {
  _sentryInstrumented?: boolean;
  $use: (cb: PrismaMiddleware) => void;
}

function isValidPrismaClient(possibleClient: unknown): possibleClient is PrismaClient {
  return !!possibleClient && !!(possibleClient as PrismaClient)['$use'];
}

/** Tracing integration for @prisma/client package */
export class Prisma implements Integration {
  /**
   * @inheritDoc
   */
  public static id: string = 'Prisma';

  /**
   * @inheritDoc
   */
  public name: string;

  /**
   * @inheritDoc
   */
  public constructor(options: { client?: unknown } = {}) {
    this.name = Prisma.id;

    // We instrument the PrismaClient inside the constructor and not inside `setupOnce` because in some cases of server-side
    // bundling (Next.js) multiple Prisma clients can be instantiated, even though users don't intend to. When instrumenting
    // in setupOnce we can only ever instrument one client.
    // https://github.com/getsentry/sentry-javascript/issues/7216#issuecomment-1602375012
    // In the future we might explore providing a dedicated PrismaClient middleware instead of this hack.
    if (isValidPrismaClient(options.client) && !options.client._sentryInstrumented) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      addNonEnumerableProperty(options.client as any, '_sentryInstrumented', true);

      options.client.$use((params, next: (params: PrismaMiddlewareParams) => Promise<unknown>) => {
        if (shouldDisableAutoInstrumentation(getCurrentHub)) {
          return next(params);
        }

        const action = params.action;
        const model = params.model;
        return trace(
          { name: model ? `${model} ${action}` : action, op: 'db.sql.prisma', data: { 'db.system': 'prisma' } },
          () => next(params),
        );
      });
    } else {
      __DEBUG_BUILD__ &&
        logger.warn(
          `Unsupported Prisma client provided to PrismaIntegration. Provided client: ${JSON.stringify(options.client)}`,
        );
    }
  }

  /**
   * @inheritDoc
   */
  public setupOnce(): void {
    // Noop - here for backwards compatibility
  }
}
